<template>
  <div class="card content-comonent">
    <div
        v-if="!(productId.review && productId.wishes)"
        class="btn-forms"
        style="display: flex; flex-direction: row; justify-content: space-between;"
    >
<!--      :to="'/review-' + orderId + '-' + GET.customerId + '-' + GET.hash"-->
      <router-link
          v-if="!(productId.review)"
          :to="'/review-' + orderId + '-' + GET.customerId"
          class="link-form btn btn-light btn-my"
          style="margin-bottom: 10px;"
      >
        Оставить отзыв</router-link>
      <router-link
          v-if="!(productId.wishes)"
          :to="'/wishes-' + orderId + '-' + GET.customerId"
          class="link-form btn btn-light btn-my"
          style="margin-bottom: 10px;"
      >
        Добавить пожелания</router-link>
    </div>
    <div style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">

      <div class="card-header">
        <button class="btn btn-menu btn-my" @click="showMenu" style="max-height: 35px; min-width: 30px;"><span>&#8801;</span></button>
        <span class="card-product-number" style="">Заказ: {{productId.number}} </span>
<!--        <div v-else></div>-->
      </div>
      <ul class="list-group list-group-flush" style="border-bottom: none">



        <li v-if="arrOrdersInfo.length && arrOrdersInfo" style="list-style-type: none; margin-top: 5px">
          <div class="list-group-item number-one"
              v-for="(item,index) in arrOrdersInfo"
               style="border-bottom: 1px solid #ccc"
          >
            <div class="order-content">
              <div class="btn-slider" style="width: 70px; height: 70px;">
                <button @click="ShowSlidebar(index)" class="btn btn-light btn-foto btn-my" :class="`Btn${item.id}`">фото</button>
              </div>
              <div class="product-inner">
                <div class="product-text">
                  <div v-for="elem in priceArr">
                    <div class="product-title">
                      {{elem.name}} - {{elem.counter}} &#8381;
                    </div>
                  </div>
                  <div class="product-description">
                    размер: {{item.size}}
                  </div>
                </div>
                <div class="product-price"></div>
              </div>
              <div class="div-forms" style="display: flex; justify-content: space-between">
                <div class="img">
                  <img v-if="item.photo"
                      :src="item.photo" alt="" style="max-width: 100%; max-height: 100%; border-radius: 0.25rem;">
                  <div v-else class="img-no">Эскиз <br> не готов</div>
                </div>
              </div>
            </div>
            <div class="slidebar slidebar-active" :class="`id${item.id}`">
              <slider ref="slidebar"
                        :index="index"
                        :item="item"
                        :length="arrOrdersInfo.length"
              ></slider>
            </div>
          </div>
        </li>


        <li v-if="!(arrOrdersInfo.length && arrOrdersInfo)" style="list-style-type: none; margin-top: 5px;">
          <div
              class="list-group-item number-two"
              v-for="(item,index) in priceArr"
              style="border-bottom: 1px solid #ccc"
          >
            <div class="order-content">
              <div class="btn-slider" style="width: 100px; height: 70px; margin-right: 10px;">
                <button
                    @click="addImg(index)"
                    class="btn btn-light btn-foto btn-my"
                    :class="'Btn'+ index"
                    v-show="checkFoto(item.name)">Добавить фото</button>
              </div>
              <div class="product-inner">
                <div class="product-text">
    <!--              <div v-for="elem in priceArr">-->
                    <div class="product-title">
                      {{item.name}} - {{item.price}} &#8381;
    <!--                </div>-->
                  </div>
                </div>
                <div class="product-price"></div>
              </div>
              <div class="div-forms" style="display: flex; justify-content: space-between">
              </div>
            </div>
            <div class="active-img "
                 :class="'AddImg'+ index"
                 v-show="checkFoto(item.name)">
              <div class="list-group-item-no-photo">
                <p style="margin: 0px;">У вас нет фотографий для эскиза. Но вы можете загрузить их ниже</p>
              </div>
              <addImg
                  :product="item"
              ></addImg>
            </div>

          </div>
        </li>
      </ul>
      <div class="list-group-item total-price" style="border: none;">
        <div>Кол-во: {{priceArr.length}} шт</div>
        <div>Итог: {{sumPrice}} &#8381;</div></div>
    </div>
  </div>
</template>

<script>
import slider from './slider';
import addImg from "./addImg";
export default {
  name: "content",
  data() {
    return {
      slideIndex: 1,
      // price: 0,
    }
  },
  props: {
    arrOrdersInfo: {
      type: Array,
    },
    productId: {
      type: Object,
    },
    priceArr: {
      type: Array,
    },
    sumPrice: {
      type: Number,
    },
    orderId: {
      type: Number,
    },
    GET: {
      type: Object
    }
  },
  components: {
    slider, addImg
  },
  methods: {
    ShowSlidebar(item) {

      // console.log(this.GET)
      // console.log(this.arrOrdersInfo)

      let clas = '.id' + this.arrOrdersInfo[item].id
      let clasbtn = '.Btn' + this.arrOrdersInfo[item].id

      let btn = document.querySelector(clasbtn);
      let slidebar = document.querySelector(clas);

      slidebar.classList.toggle('slidebar-active');
      if (!(btn.classList.contains('border-big'))) btn.classList.add('border-big');
      else btn.classList.remove('border-big');
    },

    addImg(index){
      let clas = '.AddImg' + index
      let clasbtn = '.Btn' + index
      let btn = document.querySelector(clasbtn);
      let formImg = document.querySelector(clas)
      formImg.classList.toggle('active-img')
      if (!(btn.classList.contains('border-big'))) btn.classList.add('border-big');
      else btn.classList.remove('border-big');


    },
    disabledImg(){
      let formImg = document.querySelectorAll('.AddImg')
      for(let elem of formImg){
        elem.classList.add('active-img')
      }
    },
    disableSlidebar() {
        let slidebar = document.querySelectorAll('.slidebar');
        let btn = document.querySelectorAll('.btn-foto');

        for(let i = 0; i< slidebar.length; i++){
          slidebar[i].classList.add('slidebar-active');
          if(btn[i].innerHTML == 'Добавить фото') continue
          btn[i].innerHTML = 'фото';
        }
    },
    checkFoto(name){
      let checkArr =["Полароид",
        "Панорама",
        "Именные часы",
        "Макет",
        "Флип Флоп Блестки",
        "Фотокубик",
        "Подписка в подарок",
        "Акрил",
        "Фото для кубика",
        "Браслет",
        "Модульная картина",
        "Оплаченная перерисовка",
        "Фотокартина",
        "Масло",
        "Эскиз",
        "Подписка",
        "Чокер",
        "Портрет"
      ]
      for(let elem of checkArr){
        if(elem == name) return true
      }
      return false
    },
    showMenu(){
      this.$emit('showMenu', true)
    }

    },
    mounted: function () {
      this.$root.$on('contentVue', () => {
        this.disableSlidebar()
        this.disabledImg();
      })
    },
    updated() {
      // console.log('update')
      this.$root.$emit('loaderMenuComponent')
    }
}

</script>

<style>

.content-comonent{
  margin-left: 10px;
  margin-right: 10px;
}

.btn-my{
  background: linear-gradient(to right, #f2f2f2, #ffffff);
}

.btn:hover{
  border: 1px solid #bfbfbf;
  box-shadow: none;
}

.btn-light:active:focus {
  box-shadow: none;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 700px;
}

.card {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #3c3c3c;
  border: none;
}
.card-header{

  display: flex;
  justify-content: flex-start;
}

.btn-menu{
  display: none;
}

.product-inner {
  display: flex;
  justify-content: space-between;
}

.product-text {
  padding-right: 20px;
}

.product-description {
  font-weight: 200;
  color: #525151;
}
.img{
  width: 90px;
  height: 90px;
  text-align: center;
}

.img-no{
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  padding: 20px 10px;
}

.total-price {
  /* font-weight: 400; */
  text-align: right;
  /* font-size: 1.1rem; */
}


.credentials p {
  margin: 0;
}

.order-content{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.slidebar{
  border-top: 1px solid rgba(0,0,0,.125);
  padding-top: 10px;
  margin-top: 10px;
}

.slidebar-active{
  display: none;
}

.total-price{
  display: flex;
  justify-content: space-between;
}
.btn{
  padding: 5px 10px;
  margin-right: 10px;


  transition: border .2s linear;
  border: 1px solid rgba(0,0,0,.125);
}

.btn:hover{
  background-color: rgba(0,0,0,.03);
}

.active-img{
  display: none;
}

.list-group-item{
  border: none;
}

.list-group-item-no-photo{
  background-color: #edffe8;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #1f8105;
  margin: 10px auto;
}

.border-big{
  background-color: rgba(0,0,0,.09);
}
.link-form{
  margin-right: 0px;
  font-size: 0.9rem;
}




@media (max-width: 701px){
  .content-comonent{
    width: 90vh;
    margin-right: 10px;
    position: relative;
    transition: all .5s linear;
  }
  .card-product-number{
    margin-left: 15px;
    margin-top: 8px;
  }
}
@media (max-width: 600px){
  .btn-menu{
    display: block;
    margin-bottom: 0px;
  }

  .link-form{
    padding: 2px 6px;
    /*width: 100%;*/
    font-size: 0.85rem;
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

@media (max-width: 450px){


  .div-forms, .img{
    margin: 0 auto;
  }

  .card-header{
    padding: 0.5rem 0.3rem;
  }
  .btn-menu{
    margin-bottom: 10px;
    margin-right: 5px;
  }

  .product-text{
    padding-right: 10px;
  }

}
@media (min-width: 700px){
  .content-comonent{
    min-width: 500px;
  }
}

</style>