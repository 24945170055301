<template>
  <div class="header">
<!--    <img class="header-logo" src="https://storage.yandexcloud.net/cdn.easy-mo.ru/images/logo_monro_black.png">-->
    <img class="header-logo" src="/service_project/public/img/logo/logo_monro_black.png">
  </div>
</template>

<script>
export default {
  name: "headerComponent"
}
</script>

<style scoped>
  .header{
    width: 100%;
    /*text-align: center;*/
  }

  .header-logo{
    display: block;
    width: 150px;
    margin: 30px auto;
  }
  @media (max-width: 450px){
    .header-logo{
      margin: 10px auto;
    }
  }
</style>