import Vue from 'vue'
import Router from 'vue-router'
// import main from "../src/components/content";
import pay from "../src/components/easy-pay";
import main from "./components/mainComponent";
import review from "./components/forms/review";
import wishes from "./components/forms/wishes";
import error from "./components/errorComponent";

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [

        {
            path: '/easy-pay',
            component: pay
        },
        {
            // path: '/review-:orderId-:clientId-:hash',
            path: '/review-:orderId-:clientId',
            component: review,
            props: true
        },
        {
            // path: '/wishes-:orderId-:clientId-:hash',
            path: '/wishes-:orderId-:clientId',
            component: wishes,
            props: true
        },
        {
            path: '/',
            component: main,
            props: true
        },
        {
            path: '/*',
            component: error,
            props: true
        },
    ]
})




