<template>

  <div class="div">
    <div class="container">
      <div class="form-group card" v-show="showThanks" style="margin: 0 auto">
        <label class="card-header radioBtnContainerRow">
          <h5 style="margin: 0 auto; font-size: 1rem;">Спасибо! Ваши пожелания приняты!</h5>
        </label>
      </div>
      <div class="main-header" v-show="showThanks" style="text-align: center; margin: 20px auto;">
        <!--          !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!HASH!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
        <!--          :to="'/?hash=' + hash + '&customer_id=' + clientId"-->
        <router-link
            :to="'/?customer_id=' + clientId"
            class="link-form btn btn-light"
        >Назад</router-link
        >
      </div>
      <snackbar
          ref="snackbar"
          baseSize="100px"
          :position="'bottom-center'"
          :holdTime="3000"
          :multiple="true"
      />
      <div v-show="!showThanks">
        <div
            id="successModal"
            class="modal"
            tabindex="-1"
            role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Спасибо!</h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Ваши пожелания приняты!</p>
              </div>
              <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                  Ок
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="main-header">
            <router-link
                :to="'/?hash=' + hash + '&customer_id=' + clientId"
                class="link-form btn btn-light"
            >Назад</router-link
            >
          </div>
          <div class="card top-card">
            <div class="blue-header"></div>
            <div class="card-header">
              <h1 class="display-4" style="font-size: 1.5rem">
                <strong>Ваши пожелания к заказу</strong>
              </h1>
            </div>
            <div class="card-body">
              <p class="card-text">
                Ответы на эти вопросы помогут нашему дизайнеру
                создать для Вас идеальный портрет
              </p>
            </div>
          </div>

<!--          <div class="card">-->
<!--            <div class="card-body center">-->
<!--              <img-->
<!--                  src="https://monro-art.ru/wp-content/themes/monroart/images/logo.png"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

          <!-- НОМЕР ЗАКАЗА -->
<!--          <div class="card">-->
<!--&lt;!&ndash;            <div class="card-header">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="lead">Ваш номер заказа *</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <div class="card-body">-->
<!--              <div class="form-group">-->
<!--&lt;!&ndash;                <label&ndash;&gt;-->
<!--&lt;!&ndash;                    for="order-id-input"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="bmd-label-floating"&ndash;&gt;-->
<!--&lt;!&ndash;                >Номер заказа</label&ndash;&gt;-->
<!--                <input-->
<!--                    type="hidden"-->
<!--                    class="form-control"-->
<!--                    id="order-id-input"-->
<!--                    v-model="formData.orderId"-->
<!--                    required-->
<!--                    disabled-->
<!--                />-->
<!--                <div-->
<!--                    v-if="-->
<!--                                        !formDataErrors.isOrderIdFilled &&-->
<!--                                        isBlank(formData.orderId)-->
<!--                                    "-->
<!--                >-->
<!--                  &lt;!&ndash;suppress CheckTagEmptyBody &ndash;&gt;-->
<!--                  <i-->
<!--                      class="fa fa-exclamation-circle"-->
<!--                      style="-->
<!--                                            font-size: 18px;-->
<!--                                            color: #de493f;-->
<!--                                            margin-top: 5px;-->
<!--                                        "-->
<!--                  ></i>-->
<!--                  {{ errorMessage }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div class="card" style="margin-bottom: 0px">
            <div class="card-header text-center">
              <p
                  class="lead"
                  style="
                                    font-family: 'Comfortaa', sans-serif;
                                    font-weight: bold;
                                "
              >
                Заполните данные о себе и мы подготовим для Вас
                небольшой подарок к картине:)
              </p>
            </div>
            <!--                <div class="card-body">-->
            <!--                </div>-->
          </div>

          <div class="card" style="margin-top: 0">
            <div class="card-header">
              <p class="lead">Ваш пол</p>
            </div>
            <div class="card-body">
              <div class="form-group">
                <div
                    class="form-group bmd-form-group is-filled"
                >
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="sex"
                        value="m"
                        v-model="sex"
                        id="male"
                    />
                    <label
                        class="form-check-label"
                        for="male"
                    >Мужской
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="sex"
                        value="w"
                        id="female"
                        v-model="sex"
                        checked
                    />
                    <label
                        class="form-check-label"
                        for="female"
                    >
                      Женский
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->

          <div class="card">
            <div class="card-header">
              <p class="lead">Ваш возраст</p>
            </div>
            <div class="card-body">
              <div class="form-group bmd-form-group is-filled">
                <div class="form-outline">
                  <input
                      type="text"
                      id="age"
                      name="age"
                      v-model="age"
                      placeholder="Ваш возраст"
                      class="form-control"
                  />
                  <label class="form-label" for="age"></label>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <p class="lead">
                На какой повод Вы дарите портрет?
              </p>
            </div>
            <div class="card-body">
              <div class="form-group bmd-form-group is-filled">
                <div class="form-outline">
                  <input
                      type="text"
                      id="reason"
                      name="reason"
                      v-model="reason"
                      placeholder="Повод"
                      class="form-control"
                  />
                  <label
                      class="form-label"
                      for="reason"
                  ></label>
                </div>
              </div>
            </div>
          </div>

          <!-- СТИЛЬ ФОНА -->
          <div class="card">
            <div class="card-header">
                            <span class="lead"
                            >Выберите стиль фона или прорисовки. *</span
                            >
            </div>
            <div class="card-body">
              <div class="row">
                <div
                    v-for="style in styles"
                    class="col-md-6 col-lg-6"
                >
                  <div class="radio">
                    <label>
                      <div
                          class="card"
                          width="100%"
                          style="padding: 10px"
                      >
                        <img
                            :src="style.img"
                            :alt="style.name"
                            style="
                                                        width: 100%;
                                                        height: auto;
                                                    "
                        />
                      </div>
                      <input
                          type="radio"
                          name="optionsRadios"
                          :id="style.name"
                          :value="style.caption"
                          v-model="formData.style"
                      />
                      {{ style.caption }}
                    </label>
                  </div>
                </div>

                <div class="col-md-6 col-lg-6">
                  <div class="radio">
                    <label>
                      <input
                          @click="focusCustomInput"
                          type="radio"
                          name="optionsRadios"
                          id="custom-style"
                          value="Другое"
                          v-model="formData.style"
                      />
                      Другое
                    </label>
                  </div>
                  <input
                      @click="formData.style = 'Другое'"
                      type="text"
                      class="form-control"
                      id="custom-style-input"
                      v-model="formData.styleOther"
                  />
                  <div
                      v-if="
                                            !formDataErrors.isStyleFilled &&
                                            isBlank(formData.style) &&
                                            isBlank(formData.styleOther)
                                        "
                  >
                    <!--suppress CheckTagEmptyBody -->
                    <i
                        class="fa fa-exclamation-circle"
                        style="
                                                font-size: 18px;
                                                color: #de493f;
                                                margin-top: 5px;
                                            "
                    ></i>
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header">
              <span class="lead">Платные стили</span>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                    v-for="style in paidStyles"
                    class="col-md-6 col-lg-6"
                >
                  <div class="radio">
                    <label>
                      <div
                          class="card"
                          width="100%"
                          style="padding: 10px"
                      >
                        <img
                            :src="style.img"
                            :alt="style.name"
                            style="
                                                        width: 100%;
                                                        height: auto;
                                                    "
                        />
                      </div>
                      <input
                          type="radio"
                          name="optionsRadios"
                          :id="style.name"
                          :value="style.caption"
                          v-model="formData.style"
                      />
                      {{ style.caption }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->

          <!-- ЦВЕТ ФОНА -->
          <div class="card">
            <div class="card-header">
              <span class="lead">Выберете цвет для фона</span>
            </div>
            <div class="card-body">
              <div v-for="color in colors">
                <div class="form-group">
                  <div class="radio">
                    <label>
                      <input
                          type="radio"
                          name="optionsRadios"
                          :value="color.caption"
                          v-model="formData.color"
                      />
                      {{ color.caption }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="radio">
                <label>
                  <input
                      @click="focusCustomColorInput"
                      type="radio"
                      name="optionsRadios"
                      id="custom-color"
                      value="Другое"
                      v-model="formData.color"
                  />
                  Другое
                </label>
              </div>
              <input
                  @click="formData.сolor = 'Другое'"
                  type="text"
                  class="form-control"
                  v-model="formData.colorOther"
                  id="custom-color-input"
              />
            </div>
          </div>
          <!-- -->

          <!-- ДОП ОПЦИИ -->
          <div class="card">
            <div class="card-header">
                            <span class="lead"
                            >Посмотрите ещё раз на фотографию. Есть ли на
                                ней лишние детали, которые вы не хотели бы
                                видеть в портрете? Насколько точно нужно
                                передать внешность? *</span
                            >
            </div>
            <div class="card-body">
              <div v-for="option in options">
                <div class="form-group">
                  <div class="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          name="optionsRadios"
                          :value="option.caption"
                          v-model="formData.options"
                      />
                      {{ option.caption }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="checkbox">
                <label>
                  <input
                      @click="focusCustomOptionInput"
                      type="checkbox"
                      name="optionsRadios"
                      id="custom-option"
                      value="Другое"
                      v-model="formData.options"
                  />
                  Другое
                </label>
              </div>
              <input
                  @click="formData.options.push('Другое')"
                  type="text"
                  class="form-control"
                  name="tset"
                  id="custom-option-input"
                  v-model="formData.optionsOther"
              />
              <div
                  v-if="
                                    !formDataErrors.isOptionFilled &&
                                    isBlank(formData.options.join(' ')) &&
                                    isBlank(formData.optionsOther)
                                "
              >
                <!--suppress CheckTagEmptyBody -->
                <i
                    class="fa fa-exclamation-circle"
                    style="
                                        font-size: 18px;
                                        color: #de493f;
                                        margin-top: 5px;
                                    "
                ></i>
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <!-- -->

          <div class="card">
            <div class="card-header">
              <p class="lead">
                Опишите цвет глаз человека на портрете
              </p>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label
                    for="eyes-input"
                    class="bmd-label-floating"
                >Ответ</label
                >
                <input
                    type="text"
                    class="form-control"
                    id="eyes-input"
                    v-model="formData.eyesDescription"
                />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <p class="lead">
                Опишите цвет волос человека на портрете
              </p>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label
                    for="hair-input"
                    class="bmd-label-floating"
                >Ответ</label
                >
                <input
                    type="text"
                    class="form-control"
                    id="hair-input"
                    v-model="formData.hairDescription"
                />
              </div>
            </div>
          </div>

          <div class="submit-section" style="width: 70%; max-width: 700px;">
            <div
                style="display: flex; width: 100%"
                class="button-set"
            >
              <button
                  type="button"
                  class="btn btn-dark btn-submit"
                  @click="submit"
                  style="width: 100%; margin: 0"
              >
                Отправить пожелания
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import Snackbar from "vuejs-snackbar";
const imgBasePath = window.location.origin + '/service_project/public/img/paint-styles/';

export default {
  name: "wishes",
  props: ["orderId", "clientId", "hash"],
  data() {
    return {
      showThanks: false,
      styles: [
        /*{
          name: "flowers",
          caption: "Цветочный фон",
          img: "https://monro-art.ru/g-forms/assets/img/flower.jpg",
        },
        {
          name: "shine",
          caption: "Шайн. Фон с бликами",
          img: "https://monro-art.ru/g-forms/assets/img/shine.jpg",
        },
        {
          name: "neon",
          caption: "НеонАрт. Фон неоновых улиц.",
          img: "https://monro-art.ru/g-forms/assets/img/neon.jpg",
        },
        {
          name: "comics",
          caption: "КомиксАрт. Фон с иллюстрациями комикса.",
          img: "https://monro-art.ru/g-forms/assets/img/comics.jpg",
        },
        {
          name: "sky",
          caption: "Скай. Фон приближенный к небу.",
          img: "https://monro-art.ru/g-forms/assets/img/sky.jpg",
        },
        {
          name: "classic",
          caption: "Классический. Более однотонный фон",
          img: "https://monro-art.ru/g-forms/assets/img/classic.jpg",
        },*/
        {
          name: "sharsh",
          caption:
              "Шарж. Стиль отрисовки. Перерисовки в другой стиль будут платными.",
          img: imgBasePath+"sharzh.jpg",
        },
        /*{
          name: "dream",
          caption: "Дрим. Фон с брызгами",
          img: "https://monro-art.ru/g-forms/assets/img/dream.jpg",
        },*/
        {
          name: "popart",
          caption:
              "Поп Арт. Стиль отрисовки. Перерисовки в другой стиль будут платными.",
          img: imgBasePath+"pop_art.jpg",
        },
        {
          name: "pencil",
          caption:
              "Под карандаш. Стиль отрисовки. Перерисовки в другой стиль будут платными.",
          img: imgBasePath+"pencil.jpg",
        },
        {
          name: "gta",
          caption:
              "ГТА. Стиль отрисовки. Перерисовки в другой стиль будут платными.",
          img: imgBasePath + "gta.jpg",
        },
        {
          name: "same",
          caption: "Оставить фон как на фотографии",
          img: "https://monro-art.ru/g-forms/assets/img/placeholder.jpg",
        },
      ],
      paidStyles: [
        {
          name: "maslo",
          caption:
              "Под масло +950р. Стиль отрисовки. Перерисовка в другой стиль будет платными.",
          img: imgBasePath+"maslo.jpg",
        },
        {
          name: "mult",
          caption:
              "Мультяшный стиль +950р. Стиль отрисовки. Перерисовка в другой стиль будет платными.",
          img: imgBasePath+"mult.jpg",
        },
        {
          name: "mastihin",
          caption:
              "Под мастихин +950р. Стиль отрисовки. Перерисовка в другой стиль будет платными.",
          img: imgBasePath+"mastihin.jpg",
        },
      ],
      colors: [
        {
          caption: "Светлый, нейтральный",
        },
        {
          caption: "Голубой, синий",
        },
        {
          caption: "Темный",
        },
        {
          caption: "Теплый, бежевый",
        },
        {
          caption: "Красный, розовый",
        },
        {
          caption: "На выбор художника",
        },
      ],
      options: [
        {
          caption: "Рисуем всё как есть на фото",
        },
        {
          caption: "Раскрасить чёрно-белую фотографию",
        },
        {
          caption: "Убрать мешки под глазами",
        },
        {
          caption: "Убрать второй подбородок",
        },
        {
          caption: "Сделать худее лицо",
        },
        {
          caption: "Нарисовать моложе",
        },
        {
          caption: "Немного сгладить морщины",
        },
        {
          caption: "Дорисовать обрезанную часть головы",
        },
      ],
      errorMessage: "Это поле обязательно",
      formDataErrors: {
        isOrderIdFilled: true,
        isStyleFilled: true,
        isOptionFilled: true,
      },
      formData: {
        orderId: this.orderId,
        style: "",
        styleOther: "",
        color: "",
        colorOther: "",
        options: [],
        optionsOther: "",
        eyesDescription: "",
        hairDescription: "",
      },
      formDataToSend: {
        style: "",
        styleOther: "",
        color: "",
        colorOther: "",
        options: "",
        optionsOther: "",
        eyesDescription: "",
        hairDescription: "",
      },
      sex: "m",
      age: "",
      reason: "",
    };
  },
  watch: {},
  methods: {
    focusCustomInput: function () {
      $("#custom-style-input").focus();
    },
    updatePhone: function () {
      let t = this;
    },
    focusCustomColorInput: function () {
      $("#custom-color-input").focus();
    },
    focusCustomOptionInput: function () {
      $("#custom-option-input").focus();
    },
    submit: function () {
      let data = this.formData;
      let isFine = true;
      if (this.isBlank(data.orderId)) {
        this.formDataErrors.isOrderIdFilled = false;
      }
      if (this.isBlank(data.style) && this.isBlank(data.styleOther)) {
        this.formDataErrors.isStyleFilled = false;
        isFine = false;
      }
      if (
          this.isBlank(data.options.join(" ")) &&
          this.isBlank(data.optionsOther)
      ) {
        this.formDataErrors.isOptionFilled = false;
        isFine = false;
      }
      if (isFine) {
        this.formDataToSend = Object.assign({}, data);
        let strOptions = this.formDataToSend.options.join(", ");
        this.formDataToSend.options = strOptions;
        let t = this;
        $.ajax({
          type: "POST",
          url: "https://service.monro-art.ru/vendor/sendWishes.php",
          dataType: "json",
          async: true,
          data: JSON.stringify({
            order_id: data.orderId,
            form_data: this.formDataToSend,
            sex: this.sex,
            reason: this.reason,
            age: this.age,
          }),
          success: function (data) {
            // console.log(data);
            if (data.status == "ok") {
              // console.log("wishes send");
              // console.log(t.showThanks);
              t.showThanks = true;
              // console.log(t.showThanks);
            }
          },
        });
        // console.log(this.showThanks);
      } else {
        this.$refs.snackbar.open(
            "Необходимо заполнить все обязательные поля *"
        );
      }
    },
    isBlank: function (str) {
      let res = str.split(" ").join("");
      // console.log(res);
      if (res.length == 0) return true;
      else return false;
    },
    setOrderId: function () {
      if (this.$route.query.oid != null)
        this.formData.orderId = this.$route.query.oid;
    },
  },
  mounted: function () {
console.log('qutal')  
  this.setOrderId();
  },
  components: { Snackbar },
};
</script>

<style scoped>
/* comfortaa-regular - latin */

.div {
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}
.main-header {
  width: 70%;
  max-width: 700px;
  text-align: left;
  margin: 0 auto;
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.radioBtnContainerRow{
  border: none;
}

.blue-header {
  background-color: #5f0a97;
  height: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.top-card {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.card-header-text {
  font-size: 28px;
  font-weight: 500;
}
.card-small-header-text {
  font-size: 16px;
  font-weight: 500;
}
.card {
  margin-bottom: 20px;
  margin-top: 20px;

  width: 70%;
}

.center {
  text-align: center;
}
.btn-submit {
  margin-top: 10px;
  margin-right: 0px;
  width: 100%;
  background-color: #343a40;

  transition: background-color 0.2s linear;
}

.btn-submit:hover {
  background-color: #343a40;
}

.btn-submit:active {
  border: none;
}

@media only screen and (max-width: 900px) {
  .card,
  .main-header {
    width: 100%;
  }
}


</style>
