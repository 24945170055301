<template>
    <div id="app">
        <main v-if="emailFlag">
            <div class="card card-my" style="max-width: 700px; border: 1px solid rgba(0,0,0,.125);">
                <div class="card-header">Ваш заказ:</div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="product-inner">
                            <div class="product-text">
                                <div class="product-title">
                                    {{ params.receipt_text }}
                                </div>

                                <div class="product-description" style="text-align: left">
                                    1 x {{ params.sum }}&#8381;
                                </div>
                            </div>
                            <div class="product-price">{{ params.sum }}&#8381;</div>
                        </div>
                    </li>
                    <li class="list-group-item total-price">
                        Всего: {{ params.sum }}&#8381;
                    </li>
                </ul>
            </div>


            <!-- action="https://service.monro-art.ru/vendor/yapi.php" -->
            <form
                :action="'https://easy-pay.online/yapi.php' + full_pay"
                method="post"
            >

                <div class="form-check">
                    <input class="form-check-input"
                           type="checkbox"
                           value=""
                           id="flexCheckDefault"
                           required
                    >
                    <label class="form-check-label"
                           for="flexCheckDefault"
                           style="display: contents;">
                        Я принимаю условия <a class="contract-link"
                                              href="https://service.monro-art.ru/contract/contract_oferta.pdf"
                                              target="_blank"
                    >договорa оферты</a>
                    </label>
                </div>

                <div class="form-check" >
                    <input class="form-check-input"
                           type="checkbox"
                           value=""
                           id="flexCheckDefault1"
                           required
                    >
                    <label class="form-check-label"
                           for="flexCheckDefault1"
                           style="display: contents;"
                    >
                        <!--           УБРАТЬ АТРИБУТ СКАЧИВАНИЯ-->
                        Я принимаю условия <a class="contract-link"
                                              href="https://service.monro-art.ru/contract/contract_oferta2.pdf"
                                              target="_blank"
                    >договора оферты</a> на изготовление портрета
                    </label>
                </div>

                <div class="form-check" >
                    <input class="form-check-input"
                           type="checkbox"
                           value=""
                           id="flexCheckDefault2"
                           required
                    >
                    <label class="form-check-label"
                           for="flexCheckDefault2"
                           style="display: contents;"
                    >
                        я соглашаюсь с <a class="contract-link"
                                              href="https://service.monro-art.ru/contract/processing_policy.pdf"
                                              target="_blank"
                    >политикой обработки персональных данных</a>
                    </label>
                </div>
                <input
                    name="dep_id"
                    v-model="params.departmentId"
                    type="hidden"
                />
                <input
                    name="cps_email"
                    v-model="params.cps_email"
                    type="hidden"
                />
                <input
                    name="cps_phone"
                    v-model="params.cps_phone"
                    type="hidden"
                />
                <input
                    name="customerNumber"
                    v-model="params.customerNumber"
                    type="hidden"
                />
                <input name="sum" v-model="params.sum" type="hidden"/>
                <input
                    name="currency"
                    v-model="params.currency"
                    type="hidden"
                />
                <input
                    name="departmentId"
                    v-model="params.departmentId"
                    type="hidden"
                />
                <input
                    name="ym_merchant_receipt"
                    v-model="params.ym_merchant_receipt"
                    type="hidden"
                />
                <button type="submit" class="btn btn-dark pay-btn">
                    Оплатить {{ params.sum }}&#8381;
                </button>
            </form>
        </main>
        <div v-else>
            <div class="alert alert-danger" style="max-width: 400px; margin: 0 auto; text-align: center">
                <strong>Ошибка!</strong> Почта не указана
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "easy-pay",
    props: ["customer_id", "pay"],
    data() {
        return {
            params: {},
            emailFlag: true,
            valueCheckbox: false,
            full_pay: ''
        };
    },
    methods: {
        getParams(name) {
            let params = window
                .location
                .search
                .replace('?', '')
                .split('&')
                .reduce(
                    function (p, e) {
                        let a = e.split('=');
                        p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                        return p;
                    },
                    {}
                );
            return params[name]
        },

    },
    mounted() {
        let formData = new FormData();
        if (this.getParams('full_pay')) {
            this.full_pay = '?full_pay=' + this.getParams('full_pay')
        }

        formData.append("customer_id", this.getParams('customer_id'));
        formData.append("pay", this.getParams('pay'));
        axios
            .post(
                "https://service.monro-art.ru/vendor/getYapiParams.php",
                formData,
                {
                    headers: {
                        "Content-type": "application/json",
                    },
                }
            )
            .then((res) => {

                // console.log('res_data');
                // console.log(res.data);
                if (res.data.cps_email == '') {
                    this.emailFlag = false;
                }
                this.params = res.data;

                // this.params.ym_merchant_receipt = JSON.stringify(this.params.ym_merchant_receipt);
                // console.log(this.params);
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
</script>

<style scoped>
.card-my {
    margin: 0 auto;
}

main {
    text-align: center;
}

.card-my {
    max-width: 700px !important;
}

.form-check {
    max-width: 700px;
    margin: 10px auto;
    text-align: left;
}

.contract-link {
    text-decoration: underline;
    color: #000;
}

.contract-link:hover {
    text-decoration: none;
}

.form-check-input {
    float: inherit;
    margin-right: 5px;
}

.form-check-input:checked {
    background-color: #c5de20;
    border-color: #9aae19;
}

.form-check-input:focus {
    text-decoration: none;
    border-color: #9aae19;
    box-shadow: none;
}

form {
    margin: 0 auto;
    max-width: 700px;
}

.btn {
    margin-top: 10px;
    margin-right: 0px;
    width: 100%;
    background-color: #343a40;

    transition: background-color .2s linear;
}

.btn:hover {
    background-color: #282a31;
}

.btn:active {
    border: none;
}

@media (max-width: 701px) {
    .card-my, form {
        margin: 10px;
    }

    .form-check {
        margin: 10px 20px;
    }
}


@media (max-width: 500px) {
    .alert-danger {
        margin: 50px;
    }
}
</style>